
import { defineComponent, onErrorCaptured, ref, computed } from "vue";
import { useStore } from "@/store";
import de from "@/de";
import en from "@/en";

export default defineComponent({
  mixins: [de, en],
  methods: {
    t(prop: string) {
      return this[document.documentElement.lang][prop];
    },
  },
  setup() {
    const store = useStore();

    const error = ref(false);
    // const msg = ref("");
    const vuexDump = ref("");

    onErrorCaptured((err) => {
      console.warn("Error boundary invoked:");
      error.value = true;
      console.log(err);
      vuexDump.value = JSON.stringify(store.state, undefined, 2);

      return false;
    });

    const undo = () => {
      error.value = false;
      store.commit("unredo/undo");
    };

    const resetLocalData = () => {
      localStorage.clear();
      window.location.reload();
    };

    return {
      error,
      //   msg,
      vuexDump,
      canUndo: computed(() => {
        return store.state.unredo && store.state.unredo.undoCount > 0;
      }),
      undo,
      resetLocalData,
    };
  },
});
