import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3172f582"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "comparisonSlider" }
const _hoisted_2 = ["x1", "x2"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["cx"]
const _hoisted_5 = ["x"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("g", _hoisted_1, [
    _createElementVNode("line", {
      x1: _ctx.versionMarks[0].x,
      y1: "-113",
      x2: _ctx.versionMarks[0].x * -1,
      y2: "-113"
    }, null, 8, _hoisted_2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.versionMarks, (mark, index) => {
      return (_openBlock(), _createElementBlock("a", {
        key: index,
        onClick: () => _ctx.handleCircleClick(mark.d.id)
      }, [
        _createElementVNode("circle", {
          cx: mark.x,
          cy: "-113",
          r: "1.5",
          class: _normalizeClass({
          comparisonCircle: !mark.selected,
          'comparisonCircle-selected': mark.selected,
        })
        }, null, 10, _hoisted_4),
        _createElementVNode("text", {
          x: mark.x,
          y: "-108",
          "text-anchor": "middle",
          class: "versionText"
        }, _toDisplayString(mark.label), 9, _hoisted_5)
      ], 8, _hoisted_3))
    }), 128))
  ]))
}