
import { computed, defineComponent, ref } from "vue";
import { useStore } from "@/store";
import StatisticsTable from "@/components/StatisticsTable.vue";
import StatisticsTableCategories from "@/components/StatisticsTableCategories.vue";
import {
  allAlterCategorizationKeys,
  getAlterCategorization,
} from "@/data/AlterCategories";
import de from "@/de";
import en from "@/en";
import { CATEGORY_TRANSLATIONS } from "@/data/AlterCategories";

export default defineComponent({
  mixins: [de, en],
  methods: {
    t(prop: string) {
      return this[document.documentElement.lang][prop];
    },
    translateCategoryKey(categoryKey: any) {
      const lang = document.documentElement.lang;
      const translation = CATEGORY_TRANSLATIONS[categoryKey];

      if (translation && translation[lang]) {
        return translation[lang];
      } else {
        return categoryKey;
      }
    },
  },
  components: { StatisticsTable, StatisticsTableCategories },
  setup() {
    const store = useStore();

    // whether panel is collapsed is managed locally
    const isOpen = ref(false);

    const tab = ref("");

    const go = (newTab: string) => {
      tab.value = newTab;
    };

    // onMounted(() => {
    //   console.log("hello");
    // });

    return {
      categories: allAlterCategorizationKeys,
      categoryLabel: computed(
        () => (cat: string) => getAlterCategorization(cat).label
      ),
      isOpen,
      tab,
      go,
    };
  },
});
