
import NetworkMap from "@/components/NetworkMap.vue";
import { store, useStore } from "@/store";
import { defineComponent, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import de from "@/de";
import en from "@/en";

export default defineComponent({
  mixins: [de, en],
  methods: {
    t(prop: string) {
      const langParam = useRouter().currentRoute.value.query.lang;

      const lang =
        typeof langParam === "string"
          ? langParam
          : document.documentElement.lang;

      document.documentElement.lang = lang;

      return this[lang][prop];
    },
    getGenderTranslation(gender: string) {
      switch (gender) {
        case "weiblich":
          return this.t("female");
        case "männlich":
          return this.t("male");
        case "divers":
          return this.t("diverse");
        case "nicht festgelegt":
          return this.t("notspecified");
        default:
          return "";
      }
    },
    getAlterName(alter: any) {
      const pseudo = store.state.pseudonym.active; // oder wie pseudo gespeichert wird
      return pseudo
        ? store.getters["pseudonym/pseudonize"](alter.id)
        : alter.name;
    },
  },
  name: "PdfView",
  components: { NetworkMap },
  setup: function () {
    const store = useStore();
    // knows list of Alter from vuex
    const alteri = computed(() => store.state.nwk.alteri);
    const ego = computed(() => store.state.nwk.ego);

    const createPdf = () => {
      (document.title =
        store.state.nwk.ego.name +
        " " +
        visibleNWKVersion.value?.title +
        " " +
        new Date(visibleNWKVersion.value!.date).toLocaleDateString("en-CA") +
        ".pdf"),
        window.print();
    };

    const currentVersion = computed(() => store.state.record.currentVersion);

    const visibleNWKVersion = computed(() =>
      store.state.record.versions.find(
        (version) => version.id === currentVersion.value
      )
    );

    // TODO currently: workaround -> schönere Lösung
    const readHttpGet = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let pseudo: any = useRouter().currentRoute.value.query.pseudo;
      pseudo = /true/i.test(pseudo);
      pseudo ? store.commit("pseudonym/toggle") : "";

      /* ViewOptions are now read in from local storage
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let hor: any = useRouter().currentRoute.value.query.hor;
      if (/true/i.test(hor)) {
        store.commit("view/enable", "horizons");
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let con: any = useRouter().currentRoute.value.query.con;
      con = /true/i.test(con);
      con ? "" : store.commit("view/toggle", "connections");

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let alt: any = useRouter().currentRoute.value.query.alt;
      alt = /true/i.test(alt);
      alt ? "" : store.commit("view/toggle", "alteriNames");

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let age: any = useRouter().currentRoute.value.query.age;
      age = /true/i.test(age);
      age ? store.commit("view/toggle", "ageInNwk") : "";

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let role: any = useRouter().currentRoute.value.query.role;
      role = /true/i.test(role);
      role ? store.commit("view/toggle", "roleInNwk") : "";
      */
    };

    onMounted(() => {
      // the print dialog will open immediately
      createPdf();
      readHttpGet();
    });

    return {
      alteri,
      ego,
      createPdf,
      visibleNWKVersion,
      currentVersion: currentVersion,
      versions: computed(() => store.state.record.versions),
    };
  },
});
