
import de from '@/de';
import en from '@/en';
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },
    mixins: [de, en],
    methods: {
        t(prop: string) {
            return this[document.documentElement.lang][prop];
        },
    },
  });
