
export default {
  created(): void {
    document.documentElement.setAttribute("lang", "de");
  },
  methods: {
    onLangChange(event: any): void {
      document.documentElement.setAttribute("lang", event.target.value);
    },
  },
  data: function () {
    return {
      lang: "de",
    };
  },
};
