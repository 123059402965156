
import { defineComponent, ref, computed } from "vue";
import { useStore } from "@/store";

// @ is an alias to /src
import ErrorBoundary from "@/components/ErrorBoundary.vue";
import AlteriPanel from "@/components/AlteriPanel.vue";
import EgoHeader from "@/components/EgoHeader.vue";
import EgoEditForm from "@/components/EgoEditForm.vue";
import SideMenu from "@/components/SideMenu.vue";
import NetworkMap from "@/components/NetworkMap.vue";
import UndoRedo from "@/components/UndoRedo.vue";
import StatisticsPanel from "@/components/StatisticsPanel.vue";
import ViewOptionsPanel from "@/components/ViewOptionsPanel.vue";
import ComparisonOptionsPanel from "@/components/ComparisonOptionsPanel.vue";

export default defineComponent({
  components: {
    ComparisonOptionsPanel,
    ErrorBoundary,
    EgoHeader,
    EgoEditForm,
    AlteriPanel,
    NetworkMap,
    SideMenu,
    UndoRedo,
    StatisticsPanel,
    ViewOptionsPanel,
  },

  methods: {
    onLangChange(event: any) {
      document.documentElement.setAttribute("lang", event.target.value);
      this.langKey = document.documentElement.lang;
    },
  },
  data: function () {
    return {
      lang: "de",
      langKey: "de",
    };
  },

  setup() {
    // managing the currently open Alter --> moved to store

    const store = useStore();

    const mapclicked = ref(false);

    // if Ego Name is empty --> start in Ego edit mode
    const egoEditMode = ref(
      !store.state.nwk.ego ||
        !store.state.nwk.ego.name ||
        store.state.nwk.ego.name.length == 0
    );

    const editEgoFinished = () => {
      // alteri.value.persistEgo();
      egoEditMode.value = false;
    };

    const mapclick = (coords: { distance: number; angle: number }) => {
      mapclicked.value = !mapclicked.value;
      console.log(
        "map click at (" + coords.angle + ", " + coords.distance + ")"
      );
    };

    return {
      mapclicked,
      egoEditMode,
      editEgoFinished,
      mapclick,
    };
  },
});
