
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";
import {
  analyseNWKbyCategory,
  calculateDensity,
  getOrInit,
  NetworkAnalysis,
} from "@/data/NetworkAnalysis";
import { getAlterCategorization } from "@/data/AlterCategories";
import de from "@/de";
import en from "@/en";
import { HORIZON_KEYS } from "@/data/Horizon";

export default defineComponent({
  mixins: [de, en],
  methods: {
    t(prop: string) {
      return this[document.documentElement.lang][prop];
    },
  },
  setup() {
    const store = useStore();

    const networkAnalysis = computed((): NetworkAnalysis => {
      const categorization = getAlterCategorization();
      const analysis = analyseNWKbyCategory(store.state.nwk, categorization);
      return getOrInit(analysis, categorization.categories[0]);
    });

    const density = computed((): number => {
      const { alterConnectable, intConnCount } = networkAnalysis.value;
      return calculateDensity(alterConnectable, intConnCount);
    });

    const stars = computed(() => {
      const alteri = networkAnalysis.value.stars;
      if (alteri.length > 0 && networkAnalysis.value.maxDegree > 0) {
        return document.documentElement.lang == "de"
          ? alteri.map((a) => store.getters["displayName"](a)).join(", ") +
              " (" +
              networkAnalysis.value.maxDegree +
              " Beziehungen)"
          : alteri.map((a) => store.getters["displayName"](a)).join(", ") +
              " (" +
              networkAnalysis.value.maxDegree +
              " relations)";
      } else {
        return document.documentElement.lang == "de" ? "keine" : "none";
      }
    });

    function makeComputedAlterGroup(
      group: "stars" | "isolated" | "alterZeroEdge"
    ) {
      return computed(() => {
        const alteri = networkAnalysis.value[group];
        if (alteri.length > 0) {
          return (
            alteri.length +
            " (" +
            alteri.map((a) => store.getters["displayName"](a)).join(", ") +
            ")"
          );
        } else {
          return "0";
        }
      });
    }

    const clickCell = (group: "stars" | "isolated" | "alterZeroEdge") => {
      const alteri = networkAnalysis.value[group];
      if (alteri.length > 0) {
        store.commit(
          "session/selectAlters",
          alteri.map((a) => a.id)
        );
      }
    };

    return {
      networkSize: computed(
        () =>
          networkAnalysis.value.alterConnected +
          " (" +
          networkAnalysis.value.alterConnectable +
          ")"
      ),
      sizeByGender: computed(() =>
        networkAnalysis.value.genderConnected.map(
          (g, i) => g + " (" + networkAnalysis.value.genderConnectable[i] + ")"
        )
      ),
      HORIZON_KEYS,
      sizeByHorizon: computed(() =>
        networkAnalysis.value.horizonConnected.map(
          (g, i) => g + " (" + networkAnalysis.value.horizonConnectable[i] + ")"
        )
      ),
      naehen: computed(
        () =>
          networkAnalysis.value.naehenAvg.toFixed(1) +
          " (" +
          networkAnalysis.value.naehenDev.toFixed(1) +
          ")"
      ),
      density,
      degree: computed(
        () =>
          networkAnalysis.value.degreeAvg.toFixed(1) +
          " (" +
          networkAnalysis.value.degreeDev.toFixed(1) +
          ")"
      ),
      stars,
      isolated: makeComputedAlterGroup("isolated"),
      alterZeroEdge: makeComputedAlterGroup("alterZeroEdge"),
      clickCell,
    };
  },
});
