
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { useStore } from "@/store";

import { Ego } from "@/data/Ego";
import { Gender } from "@/data/Gender";
import de from "@/de";
import en from "@/en";
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";

type InputType = HTMLInputElement | HTMLTextAreaElement;

export default defineComponent({
  mixins: [de, en],
  methods: {
    t(prop: string) {
      return this[document.documentElement.lang][prop];
    },
  },
  components: {
    EmojiPicker,
  },
  setup(props, { emit }) {
    const store = useStore();

    // name field is special because it must not be empty
    // the data item is only used for validity check & never stored
    const egoName = ref(store.state.nwk.ego.name);

    const selectedEmoji = ref(store.state.nwk.ego.emoji || "");
    const showEmojiPicker = ref(false);

    const egoNameInStore = computed(() => {
      return store.state.nwk.ego.name;
    });

    // it must be kept in sync (e.g. when loading a NWK)
    watch(egoNameInStore, (newValue) => {
      egoName.value = newValue; // store.state.ego.name;
    });

    const invalidName = computed(() => {
      return egoName.value.trim().length === 0;
    });

    // getter & setter for select dropdown
    const egoGender = computed({
      get() {
        return store.state.nwk.ego.currentGender;
      },
      set(value: string) {
        store.commit("editEgo", { currentGender: value });
      },
    });

    // generic event handlers from form to vuex
    const commitEdit = (evt: InputEvent, field: keyof Ego) => {
      const value = (evt.target as InputType).value.trim();
      if (value !== store.state.nwk.ego[field]) {
        const payload = { [field]: value };
        store.commit("editEgo", payload);
      }
    };

    const commitEditEmoji = (emoji: string) => {
      const payload = { emoji: emoji };
      store.commit("editEgo", payload);
    };

    function onSelectEmoji(emoji: any) {
      selectedEmoji.value = emoji.i;
      showEmojiPicker.value = false;
      commitEditEmoji(emoji.i);
    }

    function removeEmoji() {
      selectedEmoji.value = "";
      commitEditEmoji("");
    }

    function toggleEmojiPicker() {
      showEmojiPicker.value = !showEmojiPicker.value;
    }

    // apparently v-for needs this to be a data item
    const genderOptions = ref(Gender);

    // we need a DOM ref in order to focus
    const egofield = ref<InstanceType<typeof HTMLInputElement> | null>(null);

    const editEgoFinished = () => {
      if (invalidName.value) {
        // moving mouse cursor does not work
        // apparently editEgoFinished is not even called in the invalid state
        if (egofield.value != null) {
          (egofield.value as HTMLInputElement).focus();
        }
      } else {
        emit("edit-finished");
      }
    };

    onMounted(() => {
      // the DOM element will be assigned to the ref after initial render
      if (egofield.value != null) {
        (egofield.value as HTMLInputElement).focus();
      }
    });

    return {
      egoName,
      invalidName,
      egoGender,
      commitEdit,
      genderOptions,
      editEgoFinished,
      egofield,
      selectedEmoji,
      onSelectEmoji,
      removeEmoji,
      showEmojiPicker,
      toggleEmojiPicker,
      emoji: computed(() => store.state.view.emoji),
    };
  },
});
