import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c257ce52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label" }
const _hoisted_2 = { class: "help" }
const _hoisted_3 = { class: "columns" }
const _hoisted_4 = { class: "column" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "column" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "field is-grouped is-grouped-centered" }
const _hoisted_11 = { class: "control" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.t("relationshipsof")) + " " + _toDisplayString(_ctx.alter.name), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.t("clickoncontacttoconnect")) + " " + _toDisplayString(_ctx.alter.name) + " " + _toDisplayString(_ctx.t("clickoncontacttoconnect2")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t("possiblecontacts")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.altersNotConnected, (other) => {
          return (_openBlock(), _createElementBlock("button", {
            key: other.id,
            onClick: _withModifiers(($event: any) => (_ctx.addConnection(other.id)), ["stop"]),
            class: "button is-fullwidth"
          }, _toDisplayString(_ctx.displayName(other)), 9, _hoisted_6))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t("connectedcontacts")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.altersConnected, (other) => {
          return (_openBlock(), _createElementBlock("button", {
            key: other.id,
            onClick: _withModifiers(($event: any) => (_ctx.removeConnection(other.id)), ["stop"]),
            class: "button is-fullwidth"
          }, _toDisplayString(_ctx.displayName(other)), 9, _hoisted_9))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("p", _hoisted_11, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.editAlterFinished()), ["stop"])),
          class: "button is-primary"
        }, _toDisplayString(_ctx.t("done")), 1)
      ])
    ])
  ], 64))
}