
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

// get Zoom Behavior transform as property
// emit <nothing>

export default defineComponent({
  props: {
    transform: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const egoCoords = computed(() => props.transform.apply([0, 0]));

    const horiRadii = computed((): { r1: number; r2: number; r3: number } => {
      // project to viewport using zoomBehaviour's transform
      return {
        r1: 33.33 * props.transform.k,
        r2: 66.67 * props.transform.k,
        r3: 100 * props.transform.k,
      };
    });

    return {
      // egoLabel: computed(
      //   () =>
      //     store.state.nwk.ego.name +
      //     (store.state.nwk.ego.age.length > 0
      //       ? " (" + store.state.nwk.ego.age + "a)"
      //       : "")
      // ),
      // egoShape: computed(() =>
      //   shapeByGender(true, store.state.nwk.ego.currentGender)
      // ),
      egoCoords,
      horiRadii,
      showHorizons: computed(() => store.state.view.horizons),
    };
  },
});
