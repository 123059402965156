
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import de from "@/de";
import en from "@/en";

import AlteriPanelEntry from "@/components/AlteriPanelEntry.vue";

// handle from below: edit, edit-finished, <del>remove-alter</del> --> vuex store
// manages the edited-alter (from above or by itself) --> vuex store

export default defineComponent({
  mixins: [de, en],
  methods: {
    t(prop: string) {
      return this[document.documentElement.lang][prop];
    },
  },
  data() {
    return {
      lang: "de",
    };
  },
  components: { AlteriPanelEntry },
  props: {
    // toogled after each click on the map (resets keyboard cursor)
    mapclicked: Boolean,
  },
  setup() {
    const store = useStore();

    // knows list of Alter from vuex
    const alteri = computed(() => store.state.nwk.alteri);

    // button to add Alter
    const addAlter = () => {
      store.commit("addAlter");
    };

    return {
      alteri,
      addAlter,
      isAlterOpsAllowed: computed(() => store.getters.editedAlterValid),
    };
  },
});
