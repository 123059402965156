
import { defineComponent, computed } from "vue";

import { Sectors, SectorsEng } from "@/data/Sectors";
import de from "@/de";
import en from "@/en";

// get Zoom Behavior transform as property
// emit 'zoom-sector' with index of sector

export default defineComponent({
  mixins: [de, en],
  methods: {
    t(prop: string) {
      return this[document.documentElement.lang][prop];
    },
    langIsGerman() {
      if (document.documentElement.lang == "de") return true;
      else return false;
    },
  },
  props: {
    transform: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const showSectors = computed((): boolean[] => {
      // project to viewport using zoomBehaviour's transform
      const right = props.transform.applyX(10) < 100;
      const left = props.transform.applyX(-10) > -100;
      const bottom = props.transform.applyY(10) < 100;
      const top = props.transform.applyY(-10) > -100;

      return [right && top, left && top, left && bottom, right && bottom];
    });

    return {
      showSectors,
      Sectors,
      SectorsEng,
    };
  },
});
