export default {
  data() {
    return {
      de: {
        //networkMap: {
        family: "Familie",
        friends: "Freund*innen/Bekannte",
        colleagues: "Kolleg*innen",
        professionals: "prof. Helfer*innen",
        maptext: "Klicke in die Karte, um",
        maptext2: " die Position festzulegen",
        zoomreset: "Zoom zurücksetzen",
        clusterconnect: "komplett verbinden (Clique erzeugen)",
        deconnect: "alle Beziehungen lösen",
        closebrush: "Auswahlrechteck schließen",
        //},
        //sideMenu: {
        newNWK: "Neue NWK",
        open: "Öffnen",
        save: "Speichern",
        openDemo: "Demo laden",
        exportPNG: "PNG exportieren",
        exportkeyfigures: "Kennzahlen exportieren",
        createPDF: "PDF erstellen",
        keyfigures: "Kennzahlen",
        abouteasyNWK: "Über die easyNWK",
        sourcecode: "Source Code ",
        imprint: "Impressum",
        //},
        //statisticPanel: {
        overview: "Überblick",
        sector: "Sektor",
        profhelp: "Prof. Hilfe",
        horizon: "Horizont",
        horizoncumulative: "Horizont (kumulativ)",
        gender: "Geschlecht",
        networksize: "Netzwerkgröße (+aktivierbare)",
        sizebygender: "nach Geschlecht",
        sizebyhorizon: "nach Horizont",
        horizon_close: "nah",
        horizon_middle: "mittel",
        horizon_far: "entfernt",
        closeness: "Durchschn. Nähe (SD)",
        relationshipweight: "Beziehungsgewicht",
        totaldensity: "Dichte",
        categorydensity: "Dichte der Kategorie",
        degree: "Durchschn. Degree (SD)",
        stars: "Star(s)",
        categorystar: "Star(s) (pro Kategorie)",
        // bridges: "Brücken",
        // bridgepersons: "Brückenperson(en)",
        isolatedpersons: "Isolierte",
        personswithoutedgetotheego: "Personen ohne Kante zum Ego",
        networkmsg:
          "Anzahl der Kontakte im Netzwerk exklusive des Egos und exklusive Personen ohne Kante zum Ego (bzw. inklusive aktivierbarer Personen; d.h. Menschen, nicht verstorben)",
        closenessmsg:
          "arithmetisches Mittel der Nähe der Kontakte zum Ego in 9 konzentrischen Ringen (9=nah … 1=entfernt)",
        relationshipmsg:
          "Summierte Nähe bzw. Distanz der Kontakte zur Ankerperson. Je höher die Kennzahl, umso näher stehen die Personen der Ankerperson.",
        densitymsg:
          "Verhältnis der tatsächlich vorhandenen zu den theoretisch möglichen Verbindungen (exklusive der Verbindungen zwischen Ego und Kontaktpersonen, inklusive Personen ohne Kante zum Ego). Kennzahl liegt zwischen 0 (nur isolierte Kontakte) und 1 (jede Person im Netzwerk ist mit jeder anderen verbunden).",
        degreemsg:
          "arithmetisches Mittel der Verbindungen von Kontaktpersonen (exklusive der Verbindungen zwischen Ego und Kontaktpersonen, inklusive Personen ohne Kante zum Ego)",
        starsmsg:
          "Kontakt(e) mit den meisten Verbindungen im Netzwerk (exklusive Ego)",
        // bridgemsg: "Anzahl der Verbindungen zwischen den Sektoren im Netzwerk",
        // bridgesmsg2:
        //   "Kontakt(e), die einzelne Sektoren im Netzwerk miteinander verbinden",
        isolatedmsg:
          "Kontakt(e), die ausschließlich mit dem Ego verbunden sind",
        noedgemsg:
          "Lebende Personen ohne aktualisierte Verbindung zum Ego. Verbindungen mit anderen Personen im Netzwerk und damit indirekte Verbindung zum Ego sind möglich.",

        //},
        //viewOptions: {
        viewsettings: "Ansichtseinstellungen",
        changesize: "Textgröße:",
        anonymiseon: "Anonymisieren",
        horizonson: "Horizonte",
        connectionson: "Verbindungen",
        emojion: "Emojis",
        namesofcontactson: "Beschriftung der Kontakte",
        ageofcontactson: "zusätzlich: Alter (Jahre)",
        roleofcontactson: "zusätzlich: Rolle der Kontakte",
        anonymiseoff: "De-Anonymisieren",
        horizonsoff: "Horizonte aus",
        connectionsoff: "Verbindungen aus",
        namesofcontactsoff: "Kontaktnamen aus",
        ageofcontactsoff: "Alter der Kontakte aus",
        roleofcontactsoff: "Rolle der Kontakte aus",
        //},
        //pdfView: {
        printorpdf: "Drucken bzw. als PDF drucken",
        closeandback: "Schließen & zur easyNWK zurückkehren",
        printtext:
          "Um eine PDF Datei dieser Ansicht zu erzeugen, wählen Sie im Druckdialog 'Als PDF speichern.",
        printtext2:
          "'Sie können die Daten der Kontakte auch markieren, kopieren und in einem Textverarbeitungsprogramm einfügen.",
        //},
        //egoPanel: {},
        //egoEdit: {
        ego: "Ego",
        editego: "Ankerperson bearbeiten",
        name: "Name",
        genders: "Geschlecht",
        male: "männlich",
        female: "weiblich",
        diverse: "divers",
        notspecified: "nicht festgelegt",
        age: "Alter",
        notesaboutego: "Notizen zum Kontakt",
        note: "Notizen",
        whoisinthecenter: "Wer steht im Zentrum der NWK?",
        mandatoryfield: "Pflichtfeld",
        done: "Schließen",
        //},
        //alterPanel: {
        contacts: "Kontakte",
        nocontactsyet1: "Noch keine Kontakte vorhanden.",
        nocontactsyet2: "Klicken Sie auf den Button mit dem  ",
        nocontactsyet3: " Symbol, um Kontakte in der Netzwerkkarte anzulegen.",
        editconnections: "Beziehungen des Kontakts bearbeiten",
        deletecontact: "Kontakt entfernen",
        //},
        //alterEdit: {
        addcontact: "Kontakt hinzufügen",
        editcontact: "Kontakt bearbeiten",
        nickname: "Vorname oder Spitzname",
        socialrole: "Soziale Rolle des Kontakts, Auswahlmöglichkeiten",
        altergender:
          "biologisches und/oder soziales Geschlecht; Kategorisierung obliegt der Ankerperson",
        category: "Kategorie",
        positiononmap: "Die Position in der Karte muss noch festgelegt werden.",
        role: "Rolle",
        human: "Mensch",
        humanno: "Mensch: Nein",
        selectedEmoji: "Gewähltes Emoji: ",
        agetitle: "Optional,soziales Alter der Kontaktperson",
        deceased: "verstorben",
        deceasedyes: "Verstroben: Ja",
        deceasedcheck: "Häkchen, falls Kontaktperson verstorben",
        relationship: "Beziehung",
        relationtitle: "Aktualisierung der Verbindung",
        existingtitle:
          "Beziehung wird aktuell gepflegt, aktualisierte Verbindung.",
        multititle:
          "Anker- und Kontaktperson begegnen sich in mehreren sozialen Rollen. Beziehung erfüllt mehrere Funktionen.",
        norelationtitle:
          "Ankerperson und diese Person pflegen zurzeit keinen Kontakt, Beziehung ist nicht aktualisiert (sondern beendet/unterbrochen).",
        existing: "besteht",
        multiplex: "multiplex",
        nocurrentrelationship: "keine aktuelle Beziehung",
        notesaboutcontact: "Notizen zum Kontakt",
        nextcontact: "Nächster Kontakt",
        close: "Schließen",
        cancel: "Abbrechen",
        //},
        //alterConnections: {
        relationshipsof: "Beziehungen von",
        clickoncontacttoconnect: "Klicke auf einen Kontakt um diesen mit",
        clickoncontacttoconnect2:
          "  zu verbinden bzw. die Verbindung zu lösen.",
        possiblecontacts: "Mögliche Kontakte",
        connectedcontacts: "Verbundene Kontakte",
        //},
        //errorMessage:
        sthwentwrong: "Irgendetwas ist schief gelaufen :-(",
        withmsg: "Mit ",
        canundo: "Rückgängig",
        fullscreen: "Vollbildmodus",
        restore: "Wiederherstellen",
        canundo2: "kann der Fehler möglicherweiser behoben werden.",
        withmsg2: "Mit ",
        deletedata: "Zwischenspeicher löschen",
        deletedata2:
          "können Sie easyNWK in den Auslieferungszustand zurücksetzen.",
        attention: "Achtung:",
        attention2: "Dabei gehen die momentan bearbeiteten Daten verloren.",
        tempdata: "Zwischengespeicherte Daten:",
        tempdata2: "(können kopiert werden)",
        deletetempdata: "Zwischenspeicher löschen",
        profi: "Prof. Hilfe",
        horizon_cum: "Horizont (kumulativ)",
        circle3: "Kreis 3",
        circle2: "Kreis 2",
        circle1: "Kreis 1",
        networkwithoutprofhelp: "Netzwerk ohne prof. Hilfe",
        entirenetwork: "gesamtes Netzwerk",
        circle_3: "Kreis 3",
        circle_3_2: "Kreis 3 + 2",
        circle_3_2_1: "Kreis 3 + 2 + 1",
        professional_help: "Prof. Hilfe",
        network_without_professional_help: "Netzwerk ohne prof. Hilfe",
        entire_network: "gesamtes Netzwerk",
        lang: "de",
        formaterror: "Die ausgewählte Datei entspricht nicht dem JSON oder den NWK Format.",
        checkformat: "Bitte überprüfen Sie die Datei und versuchen Sie es erneut.",
        //Karten und Verläufe:
        versiontitle: "Karten und Verläufe",
        currentversion: "Aktuelle Karte: ",
        versionfrom: " vom ",
        newemptyversion: "Neue leere Karte",
        duplicateversion: "Karte duplizieren",
        changeversion: "Karte wechseln",
        title: "Titel",
        date: "Datum",
        deleteversion: "Karte löschen",
        titleplaceholder: "Titel der aktuellen Version",
        noemoji: "Noch kein Emoji gewählt",
        selectemoji: "Emoji auswählen",
        removeemoji: "Emoji entfernen",
      },
    };
  },
};
