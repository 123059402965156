import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ac8b8f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "contact"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "buttons are-small"
}
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "icon is-small" }
const _hoisted_6 = ["title", "disabled"]
const _hoisted_7 = { class: "icon is-small" }
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "icon is-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_AlteriEditForm = _resolveComponent("AlteriEditForm")!
  const _component_AlteriConnectionList = _resolveComponent("AlteriConnectionList")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["panel-block", {
      selected: _ctx.isSelected,
      alteriform: _ctx.isEditMode,
      clickAble: !_ctx.isEditMode,
    }]),
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleSelection()))
  }, [
    (!_ctx.isEditMode)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.displayName) + " ", 1),
          (_ctx.alter.age)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "/ " + _toDisplayString(_ctx.alter.age + " "), 1))
            : _createCommentVNode("", true),
          _createElementVNode("span", {
            class: _normalizeClass({ autovalue: _ctx.alter.roleDefault })
          }, " / " + _toDisplayString(_ctx.getTranslatedRole(_ctx.alter.role)), 3)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isEditMode && _ctx.isAlterOpsAllowed)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createElementVNode("button", {
            class: "button is-small",
            title: _ctx.t('editcontact'),
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.edit()), ["stop"]))
          }, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_font_awesome_icon, { icon: "pencil-alt" })
            ])
          ], 8, _hoisted_4),
          _createElementVNode("button", {
            class: "button is-small",
            title: _ctx.t('editconnections'),
            disabled: _ctx.isConnectionDisabled,
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.editConnections()), ["stop"]))
          }, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_font_awesome_icon, { icon: "people-arrows" })
            ])
          ], 8, _hoisted_6),
          _createElementVNode("button", {
            class: "button is-small",
            title: _ctx.t('deletecontact'),
            onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.removeAlter && _ctx.removeAlter(...args)), ["stop"]))
          }, [
            _createElementVNode("span", _hoisted_9, [
              _createVNode(_component_font_awesome_icon, { icon: "user-minus" })
            ])
          ], 8, _hoisted_8)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isBaseForm)
      ? (_openBlock(), _createBlock(_component_AlteriEditForm, {
          key: 2,
          alter: _ctx.alter,
          mapclicked: _ctx.mapclicked
        }, null, 8, ["alter", "mapclicked"]))
      : _createCommentVNode("", true),
    (_ctx.isConnectionForm)
      ? (_openBlock(), _createBlock(_component_AlteriConnectionList, {
          key: 3,
          alter: _ctx.alter
        }, null, 8, ["alter"]))
      : _createCommentVNode("", true)
  ], 2))
}