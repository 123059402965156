
import { defineComponent, ref, computed } from "vue";
import { useStore } from "@/store";
import de from "@/de";
import en from "@/en";
import { ViewOptionsFlags } from "@/data/ViewOptions";

export default defineComponent({
  mixins: [de, en],
  methods: {
    t(prop: string) {
      return this[document.documentElement.lang][prop];
    },
  },
  setup() {
    const store = useStore();

    // whether panel is collapsed is managed locally
    const isOpen = ref(false);

    const textSize = computed({
      get(): number {
        return store.state.view.labelSizeInNwk;
      },
      set(value: number) {
        store.commit("view/setLabelSizeInNwk", value);
      },
    });

    // getter & setter for checkboxes
    function accessFlag(flag: keyof ViewOptionsFlags) {
      return computed({
        get(): boolean {
          return store.state.view[flag];
        },
        set(value: boolean) {
          const payload = { flag, value };
          store.commit("view/updateFlag", payload);
        },
      });
    }

    return {
      pseudonyms: computed(() => store.state.pseudonym.active),
      togglePseudonyms: () => store.commit("pseudonym/toggle"),
      horizons: accessFlag("horizons"),
      connections: accessFlag("connections"),
      emoji: accessFlag("emoji"),
      alteriNames: accessFlag("alteriNames"),
      isOpen,
      showAge: accessFlag("ageInNwk"),
      showRole: accessFlag("roleInNwk"),
      textSize,
      //markSize,
      //changeMarkSize,
    };
  },
});
