
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { useStore } from "@/store";

import { Alter, hasOptionalChanges, isConnectable } from "@/data/Alter";
import { Gender } from "@/data/Gender";
import { Roles, RolesEng } from "@/data/Roles";
import { SYMBOL_DECEASED } from "@/assets/utils";
import { TAB_BASE } from "@/store/sessionModule";
import de from "@/de";
import en from "@/en";
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";

type InputType = HTMLInputElement | HTMLTextAreaElement;

// gender & role options
// focus name input
// prohibit invalid name
// prohibit invalid position
// emit edit-finished

export default defineComponent({
  mixins: [de, en],
  methods: {
    t(prop: string) {
      return this[document.documentElement.lang][prop];
    },
    langIsGerman() {
      if (document.documentElement.lang == "de") return true;
      else return false;
    },
  },
  props: {
    // gets Alter as prop cp. ToDo demo
    alter: {
      type: Object,
      required: true,
    },
    // toogled after each click on the map (resets keyboard cursor)
    mapclicked: Boolean,
  },
  components: {
    EmojiPicker,
  },

  setup(props) {
    const store = useStore();

    const addingNewAlter = ref(!(props.alter?.name.length > 0));


    const showEmojiPicker = ref(false);

    // name field is special because it must not be empty
    // the data item is only used for validity check & never stored
    const alterNameInUI = ref(props.alter?.name);

    const alterNameInStore = computed(() => {
      return props.alter?.name;
    });

    // it must be kept in sync (e.g. when loading a NWK)
    watch(alterNameInStore, (newValue) => {
      alterNameInUI.value = newValue;
    });

    const invalidPosition = computed(() => {
      return props.alter?.distance <= 0;
    });

    const selectedEmoji = ref(props.alter?.emoji || "");


    function onSelectEmoji(emoji: any) {
      selectedEmoji.value = emoji.i;
      showEmojiPicker.value = false;
      commitEditEmoji(emoji.i);
    }

    const commitEditEmoji = (emoji: string) => {
      const payload = {
        index: store.state.session.editIndex,
        changes: { emoji: emoji },
      };
      store.commit("editAlter", payload);
    };

    function removeEmoji() {
      selectedEmoji.value = "";
      commitEditEmoji("");
    }

    function toggleEmojiPicker() {
      showEmojiPicker.value = !showEmojiPicker.value;
    }

    // getter & setter for select dropdown
    function accessor<type>(field: keyof Alter) {
      return computed({
        get(): type {
          return props.alter[field];
        },
        set(value: type) {
          const payload = {
            index: store.state.session.editIndex,
            changes: { [field]: value },
          };
          store.commit("editAlter", payload);
        },
      });
    }

    // generic event handlers from form to vuex
    const commitEdit = (evt: FocusEvent, field: keyof Alter) => {
      const value = (evt.target as InputType).value.trim();
      if (props.alter && value !== props.alter[field]) {
        const changes = { [field]: value };
        const payload = { index: store.state.session.editIndex, changes };
        store.commit("editAlter", payload);
      }
    };

    // special event handlers for role <-- temporily clear default role
    const focusRole = (evt: FocusEvent) => {
      if (props.alter.roleDefault) {
        (evt.target as InputType).value = "";
      }
    };

    const blurRole = (evt: FocusEvent) => {
      const value = (evt.target as InputType).value.trim();

      const roles =
        document.documentElement.lang == "de"
          ? roleOptions.value
          : engRoleOptions.value;
      const role = roles.find((r) => r.label === value);

      if (role) {
        const germanValue = role.german;
        const payload = {
          index: store.state.session.editIndex,
          changes: { role: germanValue },
        };
        store.commit("editAlter", payload);
      } else {
        commitEdit(evt, "role");
      }
    };

    const localizedRole = computed(() => {
      const lang = document.documentElement.lang;
      const roles = lang === "de" ? roleOptions.value : engRoleOptions.value;
      const role = roles.find((r) => r.german === alterRole.value);
      return role ? role.label : alterRole.value;
    });

    const alterRole = accessor<string>("role");

    const invalidName = computed(() => {
      return alterNameInUI.value.trim().length === 0;
    });

    // apparently v-for needs this to be a data item
    const genderOptions = ref(Gender);
    const roleOptions = ref(Roles);
    const engRoleOptions = ref(RolesEng);

    // we need a DOM ref in order to focus
    const altername = ref<InstanceType<typeof HTMLInputElement> | null>(null);
    const domButton = ref<InstanceType<typeof HTMLButtonElement> | null>(null);

    watch(
      () => props.mapclicked,
      () => {
        if (altername.value != null) {
          (altername.value as HTMLInputElement).focus();
        }
      }
    );

    const editAlterFinished = (_event: Event, allowAddNext = true) => {
      // TODO     if (!this.invalidPosition && !this.invalidName) {
      if (invalidName.value) {
        // moving mouse cursor does not work
        // apparently editEgoFinished is not even called in the invalid state
        if (altername.value != null) {
          (altername.value as HTMLInputElement).focus();
        }
      } else {
        (domButton.value as HTMLButtonElement).focus();
        store.commit("session/closeAlterForm");

        if (addingNewAlter.value && allowAddNext) {
          store.commit("addAlter");
        }
      }
    };

    const cancelAddAlter = () => {
      if (addingNewAlter.value) {
        store.commit("cancelAddAlter", store.state.session.editIndex);
      }
    };

    onMounted(() => {
      // the DOM element will be assigned to the ref after initial render
      if (altername.value != null) {
        (altername.value as HTMLInputElement).focus();
      }

      document.onkeydown = (event: KeyboardEvent) => {
        if (event.key === "Escape" || event.key === "Esc") {
          if (document && document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }

          // check if form is open isEditMode (see #97)
          if (store.state.session.editTab !== TAB_BASE) {
            return;
          }

          if (invalidName.value || invalidPosition.value) {
            // TODO asking maybe not necessary because of undo?
            let remove = true;
            if (hasOptionalChanges(props.alter as Alter)) {
              remove = confirm("Soll dieser Kontakt gelöscht werden?");
            }
            if (remove) {
              store.commit("cancelAddAlter", store.state.session.editIndex);
            }
          } else {
            // alter is valid --> just close the form
            store.commit("session/closeAlterForm");
          }
        }
      };
    });

    return {
      addingNewAlter,
      alterNameInUI,
      alterRole,
      localizedRole,
      invalidName,
      invalidPosition,
      alterHuman: accessor<boolean>("human"),
      alterGender: accessor<string>("currentGender"),
      alterDeceased: accessor<boolean>("deceased"),
      alterEdgeType: accessor<number>("edgeType"),
      isConnectable: computed(() => isConnectable(props.alter as Alter)),
      emoji: computed(() => store.state.view.emoji),
      commitEdit,
      focusRole,
      onSelectEmoji,
      removeEmoji,
      toggleEmojiPicker,
      blurRole,
      genderOptions,
      roleOptions,
      engRoleOptions,
      editAlterFinished,
      cancelAddAlter,
      selectedEmoji,
      showEmojiPicker,
      altername,
      domButton,
      SYMBOL_DECEASED,
    };
  },
});
