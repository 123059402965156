
import { defineComponent, ref, computed, watch } from "vue";
import { useStore } from "@/store";
import { NWKVersion } from "@/data/NWKVersion";
import de from "@/de";
import en from "@/en";

//type InputType = HTMLInputElement | HTMLTextAreaElement;

export default defineComponent({
  // AR, 14 Aug 2023: prop version not needed? current version is in state.record
  /*props: {
    version: {
      type: Object,
      required: true,
    },
  },
   */
  mixins: [de, en],
  methods: {
    t(prop: string) {
      return this[document.documentElement.lang][prop];
    },
  },
  setup() {
    const store = useStore();

    const currentVersion = computed(() => store.state.record.currentVersion);

    /** the NWKVersion object of the currently visible version */
    const visibleNWKVersion = computed(() =>
      store.state.record.versions.find(
        (version) => version.id === currentVersion.value
      )
    );

    const isOpen = ref(false);

    const newVersion = ref(false);

    const firstCreation = ref(false);

    const editText = ref(false);

    const isDisabled = ref(true);

    const newNWK = ref(false); // TODO needed?

    const versions = computed(() => store.state.record.versions);

    const newVersionTitle = ref(visibleNWKVersion.value?.title);
    const newVersionDate = ref(visibleNWKVersion.value?.date);

    // local copy of NWKVersion metadata needs to be updated
    watch(visibleNWKVersion, () => {
      newVersionTitle.value = visibleNWKVersion.value?.title;
      newVersionDate.value = visibleNWKVersion.value?.date;
    });

    const editVersion = () => {
      const index: number = versions.value.findIndex(
        (version) => version.id === currentVersion.value
      );
      const changes: Partial<NWKVersion> = {
        title: newVersionTitle.value,
        date: newVersionDate.value,
      };
      store.commit("record/editVersion", {
        index,
        changes,
      });
      // newVersionDate.value = ""; // TODO AR 29 Aug 2023 why???
    };

    const deleteVersion = () => {
      store.commit("removeCurrentVersion");
    };

    return {
      editText: editText,
      isOpen: isOpen,
      newVersion: newVersion,
      newNWK: newNWK,
      firstCreation: firstCreation,
      //commitEdit,
      deleteVersion,
      addNewVersion: editVersion,
      addBlankNWK: () => store.commit("addNWKVersion"),
      duplicateNWK: () => store.commit("addNWKVersion", true),
      newVersionTitle,
      newVersionDate,
      isDisabled: isDisabled,
      currentVersion: currentVersion,
      visibleNWKVersion,
      versions: computed(() => store.state.record.versions),
      //commitEdit,
      //cancelAddVersion,
    };
  },
});
