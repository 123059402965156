import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11924310"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "coords"
}
const _hoisted_2 = ["cx", "cy", "r"]
const _hoisted_3 = ["cx", "cy", "r"]
const _hoisted_4 = ["cx", "cy", "r"]
const _hoisted_5 = ["x1", "y1", "x2", "y2"]
const _hoisted_6 = ["x1", "y1", "x2", "y2"]
const _hoisted_7 = {
  key: 1,
  id: "coords-min"
}
const _hoisted_8 = ["cx", "cy", "r"]
const _hoisted_9 = ["x1", "y1", "x2", "y2"]
const _hoisted_10 = ["x1", "y1", "x2", "y2"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showHorizons)
    ? (_openBlock(), _createElementBlock("g", _hoisted_1, [
        _createElementVNode("circle", {
          id: "horizon-base",
          cx: _ctx.egoCoords[0],
          cy: _ctx.egoCoords[1],
          r: _ctx.horiRadii.r3
        }, null, 8, _hoisted_2),
        _createElementVNode("circle", {
          id: "horizon-overlay",
          cx: _ctx.egoCoords[0],
          cy: _ctx.egoCoords[1],
          r: _ctx.horiRadii.r2
        }, null, 8, _hoisted_3),
        _createElementVNode("circle", {
          id: "horizon-overlay",
          cx: _ctx.egoCoords[0],
          cy: _ctx.egoCoords[1],
          r: _ctx.horiRadii.r1
        }, null, 8, _hoisted_4),
        _createElementVNode("line", {
          x1: _ctx.egoCoords[0],
          y1: _ctx.egoCoords[1] - _ctx.horiRadii.r3,
          x2: _ctx.egoCoords[0],
          y2: _ctx.egoCoords[1] + _ctx.horiRadii.r3
        }, null, 8, _hoisted_5),
        _createElementVNode("line", {
          x1: _ctx.egoCoords[0] - _ctx.horiRadii.r3,
          y1: _ctx.egoCoords[1],
          x2: _ctx.egoCoords[0] + _ctx.horiRadii.r3,
          y2: _ctx.egoCoords[1]
        }, null, 8, _hoisted_6)
      ]))
    : (_openBlock(), _createElementBlock("g", _hoisted_7, [
        _createElementVNode("circle", {
          cx: _ctx.egoCoords[0],
          cy: _ctx.egoCoords[1],
          r: _ctx.horiRadii.r3
        }, null, 8, _hoisted_8),
        _createElementVNode("line", {
          x1: _ctx.egoCoords[0],
          y1: _ctx.egoCoords[1] - _ctx.horiRadii.r3,
          x2: _ctx.egoCoords[0],
          y2: _ctx.egoCoords[1] + _ctx.horiRadii.r3
        }, null, 8, _hoisted_9),
        _createElementVNode("line", {
          x1: _ctx.egoCoords[0] - _ctx.horiRadii.r3,
          y1: _ctx.egoCoords[1],
          x2: _ctx.egoCoords[0] + _ctx.horiRadii.r3,
          y2: _ctx.egoCoords[1]
        }, null, 8, _hoisted_10)
      ]))
}