
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import de from "@/de";
import en from "@/en";
// import { Ego } from "@/data/Ego";

export default defineComponent({
  mixins: [de, en],
  methods: {
    t(prop: string) {
      return this[document.documentElement.lang][prop];
    },
  },
  setup() {
    const store = useStore();

    const displayName = computed(() => {
      const egoTrimmed = store.state.nwk.ego.name.trim();
      return egoTrimmed.length > 0 ? egoTrimmed : "<Neue Ankerperson>";
    });

    return {
      displayName,
      egoAge: computed(() => store.state.nwk.ego.age),
    };
  },
});
