import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fccc6b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "buttons" }
const _hoisted_2 = ["title", "disabled"]
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = ["title", "disabled"]
const _hoisted_5 = { class: "icon" }
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "button is-small",
      title: _ctx.t('canundo'),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.undo && _ctx.undo(...args))),
      disabled: !_ctx.canUndo
    }, [
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_font_awesome_icon, { icon: "undo" })
      ])
    ], 8, _hoisted_2),
    _createElementVNode("button", {
      class: "button is-small",
      title: _ctx.t('restore'),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.redo && _ctx.redo(...args))),
      disabled: !_ctx.canRedo
    }, [
      _createElementVNode("span", _hoisted_5, [
        _createVNode(_component_font_awesome_icon, { icon: "redo" })
      ])
    ], 8, _hoisted_4),
    (_ctx.canFullscreen())
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "button is-small",
          title: _ctx.t('fullscreen'),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleFullScreen && _ctx.toggleFullScreen(...args)))
        }, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_font_awesome_icon, {
              icon: _ctx.isFullScreen ? 'compress-arrows-alt' : 'expand-arrows-alt'
            }, null, 8, ["icon"])
          ])
        ], 8, _hoisted_6))
      : _createCommentVNode("", true)
  ]))
}